<template>
  <Head>
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap" rel="stylesheet">
  </Head>
  <NuxtLayout>
    <div class="min-h-dvh bg-fGreen-950 font-urbanist">
    <AppHeader/>
    <div class="pt-2 sm:pt-4 pb-4 sm:pb-12 mx-4 sm:mx-12 text-fLightText">
      <NuxtPage/>
    </div>
    </div>
    <AppFooter/>
  </NuxtLayout>
</template>
