<template>
    <header class="text-fLightText py-4 px-6 flex flex-cols items-center">
        <div class="flex flex-grow items-center space-x-4 justify-start">
            <a href="/" @click.prevent="navigateToHome">
                <AppLogo class="h-8 my-2 w-auto text-fLightText" />
            </a>
            <span class="text-lg italic">find easy and satisfying treats</span>
        </div>
        <!-- Horizontal navi -->
        <div class="justify-end hidden lg:flex items-center absolute right-0">
            <nav @click="closeAllDropdowns">
                <!-- Regular links -->
                <ul class="flex space-x-8 mr-8">
                    <li v-for="link in filteredLinks" :key="link.to" class="relative">
                        <!-- Dropdown parent -->
                        <button v-if="link.children && link.children.length > 0" @click.stop="toggleDropdown(link.label)" 
                            class="font-semibold text-xl mt-2 hover:underline flex items-center">
                            {{ link.label }}
                            <UIcon v-if="link.children && link.children.length > 0" class="ml-1 w-5 h-5" name="i-heroicons-chevron-down"/>
                        </button>
                        <!-- No dropdown Navi link -->
                        <NuxtLink v-else :to="link.to" class="font-semibold text-xl mt-2 hover:underline flex items-center">
                            {{ link.label }}
                        </NuxtLink>
                        <!-- Dropdown -->
                        <ul v-if="link.children && link.children.length > 0 && isDropdownOpen(link.label)"
                            class="absolute left-0 mt-1 bg-gray-100 text-gray-800 shadow-lg rounded-md py-2 w-44 transition-opacity duration-200 z-10">
                            <li :key="link.to" class="px-4 py-2 hover:bg-gray-200 rounded-md ">
                                <NuxtLink class="block w-full" :to="link.to">{{ link.label }}</NuxtLink>
                            </li>
                            <li v-for="sublink in link.children" :key="sublink.to" class="px-4 py-2 hover:bg-gray-200 rounded-md">
                                <NuxtLink class="block w-full" :to="sublink.to">{{ sublink.label }}</NuxtLink>
                            </li>
                        </ul>
                    </li>
                    <!-- Profile -->
                    <li v-if="user" class="relative" >
                        <button @click.stop="toggleDropdown('Profiil')" class="flex items-center">
                            <ProfileImage class="size-12 object-cover rounded-full cursor-pointer" />
                        </button>
                        <ul v-if="isDropdownOpen('Profiil')" class="absolute right-0 top-full mt-1 bg-gray-100 text-gray-800 shadow-lg rounded-md py-1 w-40 z-10">
                            <li class="px-4 py-2 hover:bg-gray-200 rounded-md">
                                <NuxtLink class="block w-full" to="/profile">Profiil</NuxtLink>
                            </li>
                            <li class="px-4 py-2 hover:bg-gray-200 rounded-md">
                                <button @click="logout" class="w-full text-red-700 text-left">Logi välja</button>
                            </li>
                        </ul>
                    </li>
                    <li v-else>
                        <AppLoginButton/>
                    </li>
                </ul>
            </nav> 
        </div>
        <!-- Vertical navi (mobile friendly) -->
        <div class="lg:hidden flex items-center">
            <button @click="toggleMenu" class="text-white">
            <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16"></path>
            </svg>
            </button>
        </div>
        <div v-if="isMenuOpen" class="fixed inset-0 bg-fGreen-950 z-50 py-6 pr-6 pl-12">
            <div class="flex flex-grow justify-end">
                <button @click="toggleMenu" class="text-white">
                    <UIcon class="w-8 h-8" name="i-heroicons-x-mark"/>
                </button>
            </div>
            <nav @click="toggleMenu">
                <ul class="flex flex-col justify-center space-y-8">
                    <li v-for="link in filteredLinks" :key="link.to" class="relative">
                        <NuxtLink :to="link.to" class="font-semibold text-xl hover:underline flex items-center">
                            {{ link.label }}
                        </NuxtLink>
                        <ul v-if="link.children && link.children.length > 0" class="space-y-6 mt-4">
                            <li v-for="sublink in link.children" :key="sublink.to" class="pl-4 hover:underline">
                                <NuxtLink :to="sublink.to" class="text-lg">{{ sublink.label }}</NuxtLink>
                            </li>
                        </ul>
                    </li>
                    <li v-if="user" class="relative">
                        <NuxtLink to="/profile" class="flex items-center gap-3">
                            <ProfileImage class="size-12 object-cover rounded-full"/>
                            <p class="text-xl font-semibold hover:underline">Profiil</p>
                        </NuxtLink>
                    </li>
                    <li v-if="user">                        
                        <button @click="logout" class="flex items-center gap-3 text-xl  font-semibold hover:underline">
                            <UIcon class="w-12 h-12" name="i-heroicons-arrow-right-start-on-rectangle-solid"/>
                            Logi välja
                        </button>
                    </li>
                    <li v-else>
                        <AppLoginButton/>
                    </li>
                </ul>
            </nav>              
        </div>

    </header>
</template>

<script setup lang="ts">
import { onBeforeRouteLeave } from 'vue-router';
const user = useAuthStore().getUser;

const openDropdown = ref<string | null>(null);
const toggleDropdown = (label: string) => openDropdown.value = openDropdown.value === label ? null : label;
const isDropdownOpen = (label: string) => openDropdown.value === label;
const closeAllDropdowns = () => openDropdown.value = null;

const logout = () => {
    const { clearSession } = useAuthStore();
    clearSession();
    navigateToHome(); 
};

const navigateToHome = () => {
    navigateTo("/");
}

// Mobile menu start
const isMenuOpen = ref(false);
const toggleMenu = () => isMenuOpen.value = !isMenuOpen.value;
// Mobile menu end

const links = [
    { label: "Avaleht", to: "/", authRequired: false },
    { 
        label: "Retseptid", 
        to: "/recipes",
        children: [
            { label: "Lisa uus retsept", to: "/recipes/add", authRequired: true },
            { label: "Lemmikretseptid", to: "/recipes/favorite", authRequired: true },
            { label: "Minu retseptid", to: "/recipes/personal", authRequired: true }
        ],
        authRequired: false
    },
    { label: "Sahver", to: "/pantry",  authRequired: true }
];

const filteredLinks = computed(() => 
    links.map(link => {
        if (link.children) {
            return {
                ...link,
                children: link.children.filter(child => !child.authRequired || user.value)
            };
        }
        return link;
    }).filter(link => !link.authRequired || user.value)
);
</script>