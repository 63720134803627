<template>
  <div class="relative h-full w-full">    
    <img 
      class="absolute inset-0 w-full h-full object-cover" 
      src="/public/feast_frontpage.webp" 
      alt="Feast cover photo" 
    />
    <div class="absolute inset-0 bg-black bg-opacity-50"></div> <!-- Dark overlay -->
    <!-- <AppHeader class="absolute top-0 inset-x-0"/> -->
    <div class="absolute inset-0 flex flex-col justify-center items-center text-white">
      <p class="text-lg italic mb-4">find easy and satisfying treats</p>
      <AppLogo class="h-20 sm:h-24 w-auto p-2 mb-8" />
      <p class="w-[90%] md:w-[75%] xl:w-[50%] text-sm sm:text-xl text-justify mb-8">
        Kas tead seda tunnet, kui külmkapi kallale minnes on see küll täis, aga süüa pole justkui midagi?
        Ning siis haarab käsi juba telefoni poole, et tellida kulleriga ebatervislik eine ülehinnatud tänavarestost?
        Ole mureta, sest Feast on siin, et päästa sinu rahakott ja tervis! Ütle meile, mis sul külmkapis leidub, ning me pakume sulle retsepte, mis need toiduained ära kasutaks.
        Et retseptide valik veel mitmekesisem oleks, saad Sahvrisse kirja panna ka need kauasäilivad toiduained, mida sul tavaliselt ikka kodus leidub.
        Oma vanaema koogiretsepte või enda väljamõeldud liha marinaadi saad jagada teiste kasutajatega... või hoida ainult enda teada, kuidas ise soovid.
      </p>
      <div v-if="!useAuthStore().getUser.value">
        <div class="flex flex-col sm:flex-row text-fGreen-950 mb-2 sm:mb-4 space-y-4 sm:space-y-0 sm:space-x-4">
          <UButton @click="loginPage" class="font-bold text-xl sm:text-2xl justify-center px-3 py-1.5 sm:px-6 sm:py-3">Logi sisse</UButton>
          <UButton @click="registerPage" class="font-bold text-xl sm:text-2xl justify-center px-3 py-1.5 sm:px-6 sm:py-3">Registreeri kasutajaks</UButton>
        </div>
        <p class="text-lg mb-4 text-center">
          Tahad enne proovida? 
          <NuxtLink to="/recipes" class="text-fGreen-500 underline">Otsi retsepte siin</NuxtLink>
        </p>
      </div>
      <div v-else>
        <NuxtLink to="/recipes" class="text-fGreen-500 underline">Tagasi retseptide nimekirja juurde</NuxtLink>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
definePageMeta({
  layout: 'frontpage',
});

const loginPage = () => {
  navigateTo("/login")
}

const registerPage=()=>{
  navigateTo("/register")
}

</script>